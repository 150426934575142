<template>
	<v-list-tile>
		<v-list-tile-avatar style="position: relative;">
			<v-icon :color="model.id ? 'blue' : 'grey'">flag</v-icon>
		</v-list-tile-avatar>

		<!-- Flag name -->
		<v-text-field v-model="model.name" :rules="[rules.name]" :label="$t('name')" maxlength="191" @keypress.enter="saveFlag(model)" />

		<!-- Flag description -->
		<v-text-field v-model="model.description" :label="$t('description')" maxlength="191" @keypress.enter="saveFlag(model)" />

		<!-- Flag actions -->
		<v-list-tile-action>
			<v-list-tile-avatar right>
				<!-- ::Update -->
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn icon class="mx-0 px-0 right" v-on="on" @click="saveFlag(model)">
							<v-icon color="primary">save</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('actions.save') }}</span>
				</v-tooltip>
				<!-- ::Delete -->
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn icon class="mx-0 px-0 right" v-on="on" @click="deleteFlag(model)">
							<v-icon color="primary">delete</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('actions.delete') }}</span>
				</v-tooltip>
			</v-list-tile-avatar>
		</v-list-tile-action>
	</v-list-tile>
</template>
<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'FlagsManagerLine',
	mixins: [SuperAdminModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			rules: {
				name: value => {
					const pattern = /^([a-z0-9_]*)$/
					return pattern.test(value) || this.$t('flag.messages.invalid_name')
				}
			}
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		}
	},
	methods: {
		saveFlag: function (flag) {
			this.$emit('save', flag)
		},
		deleteFlag: function (flag) {
			this.$emit('delete', flag)
		}
	}
}
</script>
